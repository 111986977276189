@tailwind base;
@tailwind components;
@tailwind utilities;
html {
    font-family: 'Inter', sans-serif;
}

@media only screen and (min-width: 1025px) {
    ::-webkit-scrollbar {
        width: 4px;
    }
    
    ::-webkit-scrollbar-track {
        background: #111827;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background: #4b5563;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background: #6b7280;  
    }
}

@layer utilities {
    .animation-delay-2000 {
        animation-delay: 2s;
    }
    .animation-delay-4000 {
        animation-delay: 4s;
    }
}

@keyframes movingStars {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.spin_words {
    animation: word_spin 20s infinite;
}

#wave:active {
    animation: shake 1s infinite;
}

@keyframes shake {
    10%, 90% {
        transform: rotate3d(0, 0, 1, -15deg);
    }
    
    20%, 80% {
        transform: rotate3d(0, 0, 0, 15deg);
    }
  
    30%, 50%, 70% {
        transform: rotate3d(0, 0, 1, -15deg);
    }

    40%, 60% {
        transform: rotate3d(0, 0, 0, 15deg);
    }
}

@keyframes word_spin {
    0% {
        transform: translateY(-80%);
    }
    15% {
        transform: translateY(-180%)
    }
    20% {
        transform: translateY(-180%)
    }
    30% {
        transform: translateY(-280%)
    }
    40% {
        transform: translateY(-380%)
    }
    50% {
        transform: translateY(-480%)
    }
    60% {
        transform: translateY(-380%)
    }
    70% {
        transform: translateY(-280%)
    }
    85% {
        transform: translateY(-180%)
    }
    90% {
        transform: translateY(-180%)
    }
    100% {
        transform: translateY(-80%)
    }
}

@keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.stars, .twinkling, .clouds {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.stars {
    animation: movingStars 20s linear infinite;
    background: transparent url("../src/images/stars.webp") repeat-x top center;
    z-index: 0;
}

.twinkling{
    background: transparent url("../src/images/twinkling.png") repeat top center;
    z-index: 1;
    filter: invert(9%) sepia(16%) saturate(1665%) hue-rotate(183deg) brightness(94%) contrast(99%);
    animation: move-twink-back 200s linear infinite;
}

.clouds{
    background: transparent url("../src/images/clouds.webp") repeat top center;
    z-index: 2;
    opacity: .4;
    filter: invert(12%) sepia(19%) saturate(1111%) hue-rotate(174deg) brightness(70%) contrast(90%);
    animation: move-clouds-back 200s linear infinite;
}
@keyframes ldio-mffgx1tmlt8 {
    0% { 
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%,-50%) rotate(360deg); 
    }
}
.ldio-mffgx1tmlt8 div {
    position: absolute;
    width: 50px;
    height: 50px;
    border: 10px solid #4b5563;
    border-top-color: transparent;
    border-radius: 50%;
}
.ldio-mffgx1tmlt8 div {
    animation: ldio-mffgx1tmlt8 1s linear infinite;
    top: 100px;
    left: 100px
}
.loadingio-spinner-rolling-cyx4pdgtrgj {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: none;
}
.ldio-mffgx1tmlt8 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
}
.ldio-mffgx1tmlt8 div {
    box-sizing: content-box;
}